import React from 'react';

const ServiceArea = () => {
    return (
        <section className="service-area service-bg pt-140 pb-140" id="Services">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3 text-center pb-80">
                        <div className="section-title service-title">
                            <span><img src="assets/images/service-text.png" alt="" /></span>
                            <p className="section-para"><span></span>Core Services</p>
                            <h1>Most Creative Agency
                                For Your Project</h1>
                        </div>
                    </div>
                </div>
                <div className="row pb-30">
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>01</p>
                                <i className="fas fa-bezier-curve"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Web Design & Development</h3>
                                <p>Elevate your online presence with stunning and user-friendly web designs that captivate your audience and enhance your brand's identity. <br />
                                Unlock the full potential of your online platform with expertly crafted and dynamic web development solutions tailored to your specific business needs.</p>
                                <a href="#" className="service-btn">Read More <i className="fa fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>02</p>
                                <i className="fas fa-laptop-code"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Artificial Intelligence Training and Modelling</h3>
                                <p>Unlock the full potential of your online platform with our dynamic expertise in Machine learning that helps us to 
                                    model any machine for any use in any industry.</p>
                                <a href="#" className="service-btn">Read More <i className="fa fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>03</p>
                                <i className="fas fa-microchip"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Microservices</h3>
                                <p>Streamline your operations and scale your business efficiently with our specialized microservices architecture designed for seamless integration and flexibility.</p>
                                <a href="#" className="service-btn">Read More <i className="fa fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>04</p>
                                <i className="fas fa-briefcase"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Startups Consulting</h3>
                                <p>Receive valuable insights and strategic guidance for your startup's growth and success from our experienced consultants dedicated to helping your business thrive.</p>
                                <a href="#" className="service-btn">Read More <i className="fa fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="single-service-box">
                            <div className="single-service-icon-box">
                                <p>05</p>
                                <i className="fas fa-cogs"></i>
                            </div>
                            <div className="service-box-content">
                                <h3>Infrastructure Management</h3>
                                <p>Ensure the smooth functioning of your digital infrastructure with our comprehensive management services that optimize performance and security.</p>
                                <a href="#" className="service-btn">Read More <i className="fa fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServiceArea;
