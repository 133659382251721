import React from 'react';

const CtaArea = () => {
    return (
        <section className="cta-area cta-bg pt-140 pb-140">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="cta-title">
                            <span><img src="assets/images/cta-text.png" alt="" /></span>
                            <h1>Have a Startup Idea?</h1>
                            <p>We take pride in our vast expertise in different industries because of our exposure to different Startups in these industries. <br />
                            We use our best resources to bring your startup idea to live.</p>
                            <a href="#" className="btn cta-btn">Meet with Us Now<i className="fa fa-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CtaArea;
