// App.js

import React from 'react';
// import './App.css';
import Header from './components/Header';
import Breadcrumb from './components/Breadcrumb';
import Blog from './components/Blog';
import ContactWithFooter from './components/ContactWithFooter';
import ScrollToTop from './components/ScrollToTop';
import Loading from './components/Loading';
import ServiceOffer from './components/ServiceArea';
import ServiceArea from './components/Service';

import OffCanvasSearchArea from './components/Search';
import AboutUsArea from './components/AboutUsArea';
import PortfolioArea from './components/Portfolio';
import CtaArea from './components/CTA';
import TeamArea from './components/Team';
import VideoArea from './components/Video';
import ProjectCounterArea from './components/ProjectCounter';
import PricingArea from './components/PricingModel';
import WhatsAppChatButton from './components/Whatsapp';

function App() {
  return (
    <div className="App">

      {/* <Loading />  */}
      <OffCanvasSearchArea />
      <Header />
      <Breadcrumb />
      <ServiceOffer />
      <AboutUsArea />
      <ServiceArea />
      <PortfolioArea />
      <CtaArea />
      {/* <TeamArea /> */}
      <VideoArea />
      <ProjectCounterArea />
      {/* <PricingArea /> */}
      
      {/* <Blog /> */}
      <ContactWithFooter />
      <ScrollToTop />
      <WhatsAppChatButton />
    </div>
  );
}

export default App;
