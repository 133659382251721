import React from 'react';

const PortfolioArea = () => {
    return (
        <section className="portfolio-area pt-140 pb-140" id="portfolio">
            <div className="container">
                <div className="row pb-80">
                    <div className="col-lg-5">
                        <div className="section-title portfolio-title">
                            <p className="section-para"><span></span>Our Portfolio</p>
                            <h1>Explore Our Diverse Portfolio</h1>

                        </div>
                    </div>
                    <div className="col-lg-7 text-right">
                        <div className="portfolio-btn-area">
                            <a href="#" className="btn portfolio-btn">View more <i className="fa fa-arrow-right"></i></a>
                            <a href="#" className="portfolio-btn-2">Let’s Talk <i className="fa fa-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 col-md-4">
                        <div className="single-portfolio-item">
                            <img src="assets/images/portfolio/01.png" alt="" />
                            <div className="portfolio-overlay-content">
                                <h2>SMARTEDU</h2>
                                <p>Smart education platform</p>
                                <a href="assets/images/portfolio/01.png" className="portfolio-icon img-popup"><i className="fa fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8">
                        <div className="row">
                            <div className="col-lg-6 pb-30 col-md-6">
                                <div className="single-portfolio-item">
                                    <img src="assets/images/portfolio/tt.png" alt="" />
                                    <div className="portfolio-overlay-content">
                                        <h2>Taron</h2>
                                        <p>Event app</p>
                                        <a href="assets/images/portfolio/tt.png" className="portfolio-icon img-popup"><i className="fa fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 pb-30 col-md-6">
                                <div className="single-portfolio-item">
                                    <img src="assets/images/portfolio/q.png" alt="" />
                                    <div className="portfolio-overlay-content">
                                        <h2>Qevla </h2>
                                        <p>Towing Company</p>
                                        <a href="assets/images/portfolio/q.png" className="portfolio-icon img-popup"><i className="fa fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="single-portfolio-item">
                                    <img src="assets/images/portfolio/2.jpg" alt="" />
                                    <div className="portfolio-overlay-content">
                                        <h2>CIB Development Consulting</h2>
                                        <p>Corporate Internet Banking</p>
                                        <a href="assets/images/portfolio/2.jpg" className="portfolio-icon img-popup"><i className="fa fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="single-portfolio-item">
                                    <img src="assets/images/portfolio/p.jpg" alt="" />
                                    <div className="portfolio-overlay-content">
                                        <h2>EaziPAY</h2>
                                        <p>Payment Integration system for Checkout</p>
                                        <a href="assets/images/portfolio/p.jpg" className="portfolio-icon img-popup"><i className="fa fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PortfolioArea;
