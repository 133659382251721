import React from 'react';
import './WhatsAppButton.css'; // Import the CSS styles

const WhatsAppChatButton = () => {
  const phoneNumber = '+2347069333829'; // WhatsApp number
  const prefilledMessage = 'Hi there! I am interested in learning more about...'; // Prefilled message
  
  // Encode the message to ensure it is URL-safe
  const encodedMessage = encodeURIComponent(prefilledMessage);

  return (
    <div className="whatsapp-chat-container">
      <a
        href={`https://wa.me/${phoneNumber}?text=${encodedMessage}`}
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-chat-button"
      >
        <i className="fab fa-whatsapp"></i> Chat with Us
      </a>
    </div>
  );
};

export default WhatsAppChatButton;
