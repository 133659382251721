import React from 'react';
import Slider from 'react-slick';
import ScrollToDown from './ScrollToDown';

const Breadcrumb = () => {
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // Enables auto slide show
        autoplaySpeed: 2000
    };

    return (
        <section className="hero-area hero-carousel-active" id="home">
            <Slider {...settings}>
                <div className="single-hero-area hero-bg-1 bg_cover" data-scroll-area="true">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="hero-area-content">
                                    <p className="hero-para">Revolutionize your business with  <br />
                                    EasyNet Telsurve Co's cutting-edge </p>
                                    <h1 className="hero-heading">
                                        Micro -
                                        Services & Software Developemt
                                    </h1>
                                    <p className="hero-para">delivering seamless integration and <br />
                                    superior performance for your operations. </p>
                                    <div className="hero-area-menu">
                                        <ul>
                                            <li><a href="#">Microservices</a></li>
                                            <li><a href="#">Software Developemt</a></li>
                                            <li><a href="#">System Admin / DevOps</a></li>
                                        </ul>
                                    </div>
                                    <a href="#" className="btn hero-btn">get Started <i className="fa fa-arrow-right"></i></a>
                                    <a href="https://www.youtube.com/watch?v=iogabydg2y0" className="video-btn video-popup"><i className="fa fa-play"></i></a>
                                </div>
                            </div>
                        </div>
                        <ScrollToDown />
                    </div>
                </div>
                <div className="single-hero-area hero-bg-2 bg_cover" data-scroll-area="true">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="hero-area-content">
                                    <p className="hero-para"> At EasyNet Telsurve Co, we<br />
                                     leverage on the power of </p>
                                    <h1 className="hero-heading">
                                        AI Engineering & Modelling
                                    </h1>
                                    <p className="hero-para"> to ensure our clients get the best out of their investments startups. </p>
                                    <div className="hero-area-menu">
                                        <ul>
                                            <li><a href="#">Microservices</a></li>
                                            <li><a href="#">Software Developemt</a></li>
                                            <li><a href="#">System Admin / DevOps</a></li>
                                        </ul>
                                    </div>
                                    <a href="#" className="btn hero-btn">get Started <i className="fa fa-arrow-right"></i></a>
                                    <a href="#" className="video-btn video-popup"><i className="fa fa-play"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollToDown />
                </div>
                <div className="single-hero-area hero-bg-3 bg_cover" data-scroll-area="true">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="hero-area-content">
                                    <p className="hero-para">Unlock the full potential of your infrastructure with   <br />
                                    EasyNet Telsurve Co's robust System Admin and</p>
                                    <h1 className="hero-heading">
                                        DevOps strategies,
                                    </h1>
                                    <p className="hero-para">guaranteeing optimal security and  <br />
                                    streamlined management for your business.</p>
                                    <div className="hero-area-menu">
                                        <ul>
                                        <li><a href="#">Microservices</a></li>
                                        <li><a href="#">Software Developemt</a></li>
                                        <li><a href="#">System Admin / DevOps</a></li>
                                        </ul>
                                    </div>
                                    <a href="#" className="btn hero-btn">get Started <i className="fa fa-arrow-right"></i></a>
                                    <a href="#" className="video-btn video-popup"><i className="fa fa-play"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollToDown />
                </div>
            </Slider>
        </section>
    );
}

export default Breadcrumb;
