import React from 'react';

const ProjectCounterArea = () => {
    return (
        <section className="project-counter-area">
            <div className="container">
                <div className="row pb-80">
                    <div className="col-lg-6">
                        <div className="section-title project-title">
                            <p className="section-para"><span></span>Company Statistics</p>
                            <h1>We Are Digital Transformation And Business Development Agency</h1>
                        </div>
                    </div>
                    <div className="col-lg-6 text-right">
                        <a href="#" className="btn counter-btn">get started <i className="fa fa-arrow-right"></i></a>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="counter-icon">
                            <i className="fas fa-rocket"></i>
                        </div>
                        <div className="counter-text">
                            <h1 className="count">600</h1>
                            <p>Project complete</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="counter-icon">
                            <i className="fas fa-life-ring"></i>
                        </div>
                        <div className="counter-text">
                            <h1 className="count">200</h1>
                            <p>Satisfied Clients</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="counter-icon">
                            <i className="fas fa-trophy"></i>
                        </div>
                        <div className="counter-text">
                            <h1 className="count">20</h1>
                            <p>Partners</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="counter-icon">
                            <i className="fas fa-universal-access"></i>
                        </div>
                        <div className="counter-text">
                            <h1 className="count">50</h1>
                            <p>Expert Team Members Worldwide</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProjectCounterArea;
