import React from 'react';

const AboutUsArea = () => {
    return (
        <section className="about-us-area" id="About">
            <div className="container">
                <div className="row">
                    <div className="about-us-img">
                        <img src="assets/images/about-us/1.png" alt="" className="about-img-1" />
                        <img src="assets/images/about-us/2.png" alt="" className="about-img-2" />
                    </div>
                    <div className="col-lg-5 offset-lg-7">
                        <div className="about-us-content">
                            <div className="section-title about-us-title">
                                <p className="section-para"><span></span>About Us</p>
                                <h1>Your Most Trusted Partner
                                    For Digital Transformation</h1>
                            </div>
                            <div className="about-us-text">
                                <span>E</span>
                                <p>EASYNET TELSURVE CO is a leading digital transformation company, empowering individuals, businesses, and corporations with cutting-edge solutions. With an experienced team and a deep understanding of technology trends, we deliver tailored services for all digital needs.

                                    Our comprehensive services span digital strategy consulting, software development, IT infrastructure management, digital marketing, and cybersecurity. We are dedicated to providing exceptional support throughout the transformation journey, ensuring our clients achieve their business objectives in a rapidly evolving digital landscape.</p>
                            </div>
                            <ul className="about-menu">
                                <li><a href="#"><i className="fas fa-check"></i>Web Design</a></li>
                                <li><a href="#"><i className="fas fa-check"></i>Web Development</a></li>
                                <li><a href="#"><i className="fas fa-check"></i>Microservices</a></li>
                                <li><a href="#"><i className="fas fa-check"></i>Startups Consulting</a></li>
                                <li><a href="#"><i className="fas fa-check"></i>Infrastructure management</a></li>
                            </ul>
                            <a href="#Contact" className="btn about-btn">Contact Us <i className="fa fa-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutUsArea;
