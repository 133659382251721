import React from 'react';

const ServiceOffer = () => {
    return (
        <section className="service-area service-categories pt-100 pb-60">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="single-service-area">
                            <span className="service-num">01</span>
                        </div>
                        <div className="service-icon">
                            <img src="assets/images/service-icon/1.png" alt="" />
                        </div>
                        <div className="service-content">
                            <h4>Microservices Architecture & Software Development Solutions</h4>
                            <p>Optimize your digital infrastructure for streamlined operations and enhanced performance.<br />
                            Unlock powerful software solutions tailored to your business needs for seamless operations and growth.</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="single-service-area">
                            <span className="service-num">02</span>
                        </div>
                        <div className="service-icon">
                            <img src="assets/images/service-icon/2.png" alt="" />
                        </div>
                        <div className="service-content">
                            <h4> AI Engineering & Modelling</h4>
                            <p>Take advantage of Artificial Intelligence to gain competitive advantage over your competitors and enjoy higher ROI in your business venture.</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="single-service-area">
                            <span className="service-num">03</span>
                        </div>
                        <div className="service-icon">
                            <img src="assets/images/service-icon/3.png" alt="" />
                        </div>
                        <div className="service-content">
                            <h4>DevOps Strategies</h4>
                            <p>Enhance your organizational efficiency and collaboration with cutting-edge DevOps strategies and implementations.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServiceOffer;
