import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';

const ContactWithFooterArea = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => emailjs.init("sEXZK-Jh09yP7hC0P"), []);
    // Add these
    const handleSubmit = async (e) => {
        e.preventDefault();
        const serviceId = "service_jg65yhc";
        const templateId = "template_17ofu1u";
        try {
            setLoading(true);
            const result = await emailjs.send(serviceId, templateId, {
                to_name: e.target.name.value,
                from_email: e.target.email.value,
                phone: e.target.phone.value,
                message: e.target.message.value,
            });

            if (result.status === 200) {
                alert("Welcome on board! We have received your email, will reach out to you in not time");
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className="contact-with-footer-area contact-bg pt-100" id="Contact">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3 text-center pb-80">
                        <div className="section-title contact-title">
                            <span><img src="assets/images/contact-text.png" alt="" /></span>
                            <p className="section-para"><span></span>Message Us</p>
                            <h1>Don't Hesitate To Contact
                                With Us</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="contact-form-area">
                            <form onSubmit={handleSubmit}>
                                <div className="single-contact-field">
                                    <input type="text" name = "name" placeholder="Your Name" />
                                    <span className="far fa-user"></span>
                                </div>

                                <div className="single-contact-field">
                                    <input type="email" name="email" placeholder="Your email" />
                                    <span className="fas fa-envelope-open-text"></span>
                                </div>

                                <div className="single-contact-field">
                                    <input type="text" name="phone" placeholder="Your phone" />
                                    <span className="fas fa-phone"></span>
                                </div>
                                <div className="single-contact-field textarea">
                                    <textarea name="message" id="message" cols="30" rows="10" placeholder="Your Message"></textarea>
                                    <span className="fas fa-pencil-alt"></span>
                                    <button type="submit" className="btn contact-btn">send message <i className="fa fa-arrow-right"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="single-address-area">
                            <span><i className="fa fa-arrow-right"></i>+234 (706) 9333 829</span>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="single-address-area">
                            <span><i className="fa fa-arrow-right"></i>sales@easynetsurveco.com</span>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="single-address-area">
                            <span><i className="fa fa-arrow-right"></i>Nigeria, United Kingdom, Benin Rep</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <p>&copy; 2023 EASYNET TELSURVE CO. All Rights Reserved</p>
                        </div>
                        <div className="col-lg-6">
                            <ul className="footer-links">
                                <li><a href="https://web.facebook.com/easynetsurve"><i className="fab fa-facebook-f"></i></a></li>
                                {/* <li><a href="#"><i className="fab fa-twitter"></i></a></li> */}
                                <li><a href="https://www.linkedin.com/company/eaynettelsurveco/mycompany/?viewAsMember=true"><i className="fab fa-linkedin"></i></a></li>
                                <li><a href="https://www.instagram.com/easynet_telsurve_co"><i className="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactWithFooterArea;
